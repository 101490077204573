import { useEffect, useState } from 'react';
import './number-input.css';
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc';
function NumberInput({value, onChange, max='', min='0', placeholder, onFocus}) {
    const [numberValue, setNumberValue] = useState(value);
    useEffect(()=>{
      setNumberValue(value);
    }, [value]);
  return (
    <div className='number-input'>
        <input type="number" onFocus={onFocus} placeholder={placeholder || ''} max={max} min={min} value={`${numberValue}`} onChange={e=>{
          if((e.target.value <= max || max === '') && e.target.value >= min){
            setNumberValue(e.target.value);
            onChange(e.target.value);
          }
          }} />
        <button type='button' onClick={()=> { 
          if(numberValue < max || max === ''){
          setNumberValue(prv=> prv + 1); onChange(numberValue + 1);
          }}}><VscTriangleUp /></button>
        <button type='button' onClick={()=> {
          if(numberValue > min){
          setNumberValue(prv=> prv - 1); onChange(numberValue - 1);
        }
          }}><VscTriangleDown /></button>
    </div>
  )
}

export default NumberInput