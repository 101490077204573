import React, { useState } from "react";
import FormError from "../../components/formError/FormError";
import "../guest/guest.css";
import { SERVER_URL } from "../../variables";
import { useNavigate, useParams } from "react-router-dom";
import LoadingIcon from "../../components/loadingIcon/LoadingIcon";
function GuestVerify() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { tokenId } = useParams();
  async function handleSubmit(e) {
    e.preventDefault();
    if (!email)
      return setError("Please fill all fields with valid data.");
    setLoading(true);
    const res = await fetch(`${SERVER_URL}/guest/confirm-email/${tokenId}`, {
      method: "POST",
      body: JSON.stringify({
        email
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (res.ok) {
      return navigate(`/guest/${tokenId}`);
    }
    if(!res.ok){
        const {message} = await res.json();
      setError(message || 'Link Expired, please request a new Link.')
    }
    setLoading(false);
  }
  return (
    <div className="guest-page">
      <form onSubmit={handleSubmit}>
        <h1 className="form-header">Enter Guest Credentials</h1>
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        {error ? <FormError>{error}</FormError> : null}
        <button type="submit" className="cta-btn-123 red light">
          {loading ? <LoadingIcon /> : "Enter"}
        </button>
      </form>
    </div>
  );
}

export default GuestVerify;
