import { createSlice } from '@reduxjs/toolkit'


export const userSlice = createSlice({
  name: 'user',
  initialState:{
    value:{
    userName:'',
    email:'',
    firstName:'',
    lastName:'',
    phone:'',
    uid:'',
  },
  isReserved: false,
  isLoggedIn: false,
  canViewQueuelist: false
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
    setIsReserved: (state, action)=>{
      state.isReserved = action.payload;
    },
    setLoggedIn: (state, action)=>{
      state.isLoggedIn = action.payload;
    },
    setCanViewQueuelist: (state, action)=>{
      state.canViewQueuelist = action.payload;
    }
  },
})

export const { setUser, setIsReserved , setLoggedIn, setCanViewQueuelist } = userSlice.actions;

export default userSlice;