import './back-btn.css'
import { useNavigate } from 'react-router-dom'
import { IoArrowBackOutline } from 'react-icons/io5'

function BackBtn({ style }) {
  const navigate = useNavigate();
  return (
    <button className="back-btn" type='button' style={style || {}} onClick={() => navigate(-1)}>
    <IoArrowBackOutline />
  </button>
  )
}

export default BackBtn