import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
}

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    push: (state, action)=>{
        state.value = [...state.value, action.payload]
    }
  },
})

export const { set, push } = sectionsSlice.actions;

export default sectionsSlice;