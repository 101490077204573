import './logo.css'
function Logo({style}) {
  return (
    <svg version="1.2" style={style} className='glounge-logo' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 513 501" width="513" height="501">
	<g id="Layer 1">
		<path id="&lt;Path&gt;" d="m303.2 437.9l-40.9 43.3-169.5-160.2 40.9-43.3 126.2 119.3 121.8-128.9 43.3 40.9z"
    strokeLinejoin="round"/>
		<path id="&lt;Path&gt;" d="m264.7 349.6l-178.7-168.8 40.9-43.3 112-118.5 43.3 40.9-112 118.5 92.1 87 71.1-75.2-24.7-23.4-51.2 54.3-43.4-41 92.2-97.5 43.3 40.9 24.7 23.4 43.3 40.9z"
    strokeLinejoin="round"/>
	</g>
</svg>
  )
}

export default Logo;