import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import FormError from "../../components/formError/FormError";
import "./change-queuelist-pass.css";
import "../editProfile/edit-profile.css";
import LoadingIcon from "../../components/loadingIcon/LoadingIcon";
import { SERVER_URL } from "../../variables";
import { useEffect, useState } from "react";
import * as joi from "joi";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../components/backBtn/BackBtn";
import NumberInput from "../../components/numberInput/NumberInput";

function ChangeQueueListPass() {
  async function fetchAdminProfile() {
    try {
      const res = await fetch(`${SERVER_URL}/admin/profile`, {
        headers: { "x-access-token": localStorage.getItem("token") },
      });
      if (res.ok) {
        const adminData = await res.json();
        const { adminName, firstName, lastName, isBanned, _id, allowBanUsers } =
          adminData;
        setAdmin({
          adminName,
          firstName,
          lastName,
          isBanned,
          aid: _id,
          allowBanUsers,
        });
      } else {
        const resText = await res.json();
        if (["jwt", "token", "expired"].includes(resText.message)) {
          localStorage.removeItem("token");
          window.location.href = `${window.location.origin}/super/login`;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchUpdateTimeDifference();
    fetchAdminProfile();
  }, []);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const [admin, setAdmin] = useState({});
  const [password, setPassword] = useState({
    confirmNewPassword: "",
    newPassword: "",
  });
  const [timeDifferenceChanged, setTimeDifferenceChanged] = useState(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);
  const [isPasswordChecking, setIsPasswordChecking] = useState(false);
  const [timeDifference, setTimeDifference] = useState(60);
  const [passwordError, setPasswordError] = useState("");
  const [enteredPassword, setEnteredPassword] = useState({
    password: "",
    isEntered: false,
    error: "",
  });
  async function handleAdminPassword(e) {
    e.preventDefault();
    try {
      setIsPasswordChecking(true);
      const res = await fetch(`${SERVER_URL}/admin/password/check`, {
        method: "post",
        headers: {
          "x-access-token": localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          adminPassword: enteredPassword.password,
        }),
      });
      if (res.ok) {
        setIsPasswordChecking(false);
        return setEnteredPassword((prv) => ({ ...prv, isEntered: true }));
      }
      const resText = await res.json();
      setIsPasswordChecking(false);
      if (
        resText?.message?.toLowerCase().includes("jwt") ||
          resText?.message?.toLowerCase().includes("token") ||
          resText?.message?.toLowerCase().includes("expired")
      ) {
        localStorage.removeItem("token");
        return (window.location.href = `${window.location.origin}/super/login`);
      } else if (resText.message.length < 40)
        setEnteredPassword((prv) => ({ ...prv, error: resText.message }));
    } catch (err) {
      setIsPasswordChecking(false);
      console.dir(err);
    }
  }

  const handleTimeDifferenceChange = (newValue) => {
    setTimeDifference(newValue);
    !timeDifferenceChanged && setTimeDifferenceChanged(true);
  };

  async function handleUpdatePassword(e) {
    e.preventDefault();
    const schema = joi.object({
      newPassword: joi.string().min(8).max(255).required(),
      confirmNewPassword: joi.string().min(8).max(255).required(),
    });
    const errors = schema.validate({
      newPassword: password.confirmNewPassword,
      confirmNewPassword: password.confirmNewPassword,
    });
    if (errors.error) return setPasswordError(errors.error.details[0].message);
    if (password.newPassword !== password.confirmNewPassword)
      return setPasswordError("Passwords Doesn't match");
    setIsPasswordUpdating(true);
    const res = await fetch(`${SERVER_URL}/queuelist/pass`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      method: "put",
      body: JSON.stringify({
        adminPassword: enteredPassword.password,
        newPassword: password.newPassword,
      }),
    });
    if (res.ok) {
      setIsPasswordUpdating(false);
      navigate(`/admin/dashboard`);
      passwordError && setPasswordError("");
      return setPassword({
        confirmNewPassword: "",
        newPassword: "",
      });
    }
    setIsPasswordUpdating(false);
    const message = await res.json();
    if (message) setPasswordError(message);
  }

  async function handleUpdateTimeDifference() {
    const res = await fetch(
      `${SERVER_URL}/super-admin/queuelist/time-difference`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        method: "put",
        body: JSON.stringify({
          timeDifference,
        }),
      }
    );
    if (res.ok) {
      navigate(-1);
    }
  }

  async function fetchUpdateTimeDifference() {
    const res = await fetch(
      `${SERVER_URL}/super-admin/queuelist/time-difference`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    if (res.ok) {
      const newTimeDifference = await res.json();
      setTimeDifference(newTimeDifference.timeDifference);
    }
  }

  return enteredPassword.isEntered ? (
    <>
      <BackBtn />
      <div className="change-queuelist-pass">
        <div
          className="field"
          style={{ marginTop: "1em", width: "max-content" }}
        >
          <h2>Change Queuelist Password</h2>
          <div className="inputs">
            <input
              type="password"
              placeholder="New Pass"
              value={password.newPassword}
              onChange={(e) =>
                setPassword((prv) => ({
                  ...prv,
                  newPassword: e.target.value,
                }))
              }
            />
            <input
              type="password"
              placeholder="Confirm New Pass"
              value={password.confirmNewPassword}
              onChange={(e) =>
                setPassword((prv) => ({
                  ...prv,
                  confirmNewPassword: e.target.value,
                }))
              }
            />
          </div>
          {passwordError ? <FormError>{passwordError}</FormError> : null}
          <button
            className="cta-btn-123 red light"
            onClick={handleUpdatePassword}
          >
            {isPasswordUpdating ? <LoadingIcon /> : "Confirm"}
          </button>
        </div>
        {/* <div className="time-difference-input">
          <h2>Change vip time difference</h2>
          <NumberInput
            min={10}
            max={1440}
            value={timeDifference}
            onChange={handleTimeDifferenceChange}
          />
          {timeDifferenceChanged ? (
            <button
              className="cta-btn-123 red light"
              type="button"
              onClick={handleUpdateTimeDifference}
            >
              Confirm
            </button>
          ) : null}
        </div> */}
      </div>
    </>
  ) : (
    <form className="admin-password-form">
      <BackBtn
        style={{
          marginBottom: "2em",
        }}
      />
      <div className="password-box">
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Admin password"
          onChange={(e) =>
            setEnteredPassword((prv) => ({ ...prv, password: e.target.value }))
          }
        />
        <button
          type="button"
          className="show-password"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          {passwordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
        </button>
      </div>
      {enteredPassword.error ? (
        <FormError>{enteredPassword.error}</FormError>
      ) : null}
      <button className="cta-btn-123 red light" onClick={handleAdminPassword}>
        {isPasswordChecking ? <LoadingIcon /> : "Confirm"}
      </button>
    </form>
  );
}

export default ChangeQueueListPass;
