import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import FormError from "../../components/formError/FormError";
import "./guest.css";
import jwtDecode from "jwt-decode";
import { SERVER_URL } from "../../variables";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setLoggedIn, setUser } from "../../store/userReducer";
import { setToken } from "../../store/tokenReducer";
import LoadingIcon from "../../components/loadingIcon/LoadingIcon";
function Guest() {
  const countries = getCountries().filter((country) => !"IL".includes(country));
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [phoneNum, setPhoneNum] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { tokenId } = useParams();
  async function handleSubmit(e) {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneNum) || !userName)
      return setError("Please fill all fields with valid data.");
    setLoading(true);
    const res = await fetch(`${SERVER_URL}/guest/${tokenId}`, {
      method: "POST",
      body: JSON.stringify({
        username: userName,
        phone: phoneNum,
        email,
        code,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.ok) {
      const { token } = await res.json();
      console.log(token);
      const claims = jwtDecode(token);
      localStorage.setItem("token", token);
      dispatch(setUser(claims));
      dispatch(setLoggedIn(true));
      dispatch(setToken(token));
      return navigate(`/guest/booking`);
    }
    if (!res.ok) {
      const { message } = await res.json();
      setError(message || "Link Expired, please request a new Link.");
    }
    setLoading(false);
  }
  return (
    <div className="guest-page">
      <form onSubmit={handleSubmit}>
        <h1 className="form-header">Enter Guest Credentials</h1>
        <input
          type="text"
          placeholder="Username"
          onChange={(e) => setUserName(e.target.value)}
          value={userName}
        />
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <PhoneInput
          countries={countries}
          placeholder="Phone"
          value={phoneNum}
          onChange={(newPhone) => {
            if (newPhone) setPhoneNum(newPhone);
            else return console.log(newPhone);
            setError((prv) =>
              !isValidPhoneNumber(newPhone.toString() || phoneNum)
                ? "Enter a valid phone number"
                : ""
            );
          }}
          defaultCountry="LB"
        />
        <input
          type="text"
          placeholder="Verification Code"
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
        {error ? <FormError>{error}</FormError> : null}
        <button type="submit" className="cta-btn-123 red light">
          {loading ? <LoadingIcon /> : "Enter"}
        </button>
      </form>
    </div>
  );
}

export default Guest;
