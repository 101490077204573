import './floating-wa-icon.css'

import { AiOutlineWhatsApp } from 'react-icons/ai';

function FloatingWaIcon() {
  return (
    <div className='floating-wa-icon'>
        <a href="https://api.whatsapp.com/send/?phone=9616201146&text=Website visitor, I have an enquiry." target='_blank'><AiOutlineWhatsApp /></a></div>
  )
}

export default FloatingWaIcon