import './footer.css';
import { GrInstagram } from 'react-icons/gr';
import { FaFacebookSquare } from 'react-icons/fa';
export default function Footer() {
  return (
    <footer className='footer'>
        <div className="icons-row">
            <a href="https://www.instagram.com/gloungelb/"><GrInstagram/></a>
            <a href="https://www.facebook.com/GLoungeLB/"><FaFacebookSquare /></a>
        </div>
        <p className='copyrights'>GLOUNGE © {new Date().getFullYear()}. All Rights Reserved.</p>
        <p>Developed By <a href="https://careerspack.com" target="_blank">CareerSpack</a></p>
    </footer>
  )
}
