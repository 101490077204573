import { createSlice } from '@reduxjs/toolkit'

export const redirectSlice = createSlice({
  name: 'redirect',
  initialState: {
    value: ''
},
  reducers: {
    setRedirect: (state, action) => {
      state.value = action.payload;
    }
  },
})

export const { setRedirect } = redirectSlice.actions;

export default redirectSlice;