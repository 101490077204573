import { useRef } from 'react';
import { Link } from 'react-router-dom';
import './not-found.css'
export default function NotFound() {
  return (
    <div className='not-found'>
        <div className="wrapper-404">
        <h2 className="fourofour">404</h2>
        <h2 className='not-found-header'>Not Found</h2>
        <p>This page maybe removed or doesn't exist.</p>
        <Link to='/' className='cta-btn-123 red'>Go Back</Link>
        </div>
    </div>
  )
}
