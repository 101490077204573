import { Link } from 'react-router-dom';
import './expired-popup.css';
function ExpiredPopup({to, onClicked}) {
  return (
    <div className='expired-popup'>
        <h1>Your session has expired</h1>
        <Link className='cta-btn-123 red light' to={to} onClick={onClicked}>Login</Link>
    </div>
  )
}

export default ExpiredPopup