import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import './home.css';
import Footer from './../../components/footer/Footer';
import FloatingWaIcon from '../../components/floatingWaIcon/FloatingWaIcon';
export default function Home() {
  const navigate = useNavigate();
  const isAdmin = useSelector(state=> state.user.value.isAdmin) || false;
  useEffect(()=>{
    if((localStorage.getItem('token') || sessionStorage.getItem('token')) && !isAdmin) navigate('/user/dashboard');
    else if((localStorage.getItem('token') || sessionStorage.getItem('token')) && isAdmin) navigate('/admin/dashboard');
  },[]);
  return (
    <div className='home-page'>
      <FloatingWaIcon />
        <section className="hero">
          <Logo />
          <div className="cols">
          <div className="column">
        <h2 className='subheader'>Book <br /> Play <br /> Repeat</h2>
        <Link to='/user/login' className='cta-btn-123 red'>Start Booking</Link>
        <a
        className='activate-link'
        target='_blank'
        href="https://api.whatsapp.com/send/?phone=9616201146&text=Hello from gloungelb.com I want to activate my account"
        >Activate your account</a>
        </div>
        </div>
        <Footer />
        </section>
    </div>
  )
}
