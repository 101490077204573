import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
}

export const queuesSlice = createSlice({
  name: 'queues',
  initialState,
  reducers: {
    setQueues: (state, action) => {
      state.value = action.payload;
    }
  },
})

export const { setQueues } = queuesSlice.actions;

export default queuesSlice;