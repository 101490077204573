import '../userProfile/user-profile.css';
import { SERVER_URL, WEB_SOCKET_URL } from '../../variables';
import { useSelector, useDispatch } from 'react-redux';
import { CgProfile } from 'react-icons/cg';
import { Link } from 'react-router-dom';
function AdminProfile() {
  return (
    <div className='user-profile'>
    <h1 className='page-title'><CgProfile />My profile</h1>
{/* <div className='user-card'>
    <button className='edit-button' onClick={()=> setEdited(true)}>Edit password</button>
</div> */}
{/* 
{edited? 
        <div className="field">
            <h2>Email Address</h2>
            <input type='email' name='Email Address' placeholder='Email Address'
            onChange={e=> handleChange('email', e.target.value)}
            value={updated.email} />
            {formErrors.email ? <FormError>{formErrors.email}</FormError> : null}
        </div>:
        null} */}

    </div>
  )
}

export default AdminProfile