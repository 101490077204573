import { Link, useLocation, useNavigate } from 'react-router-dom';
import './navbar.css';
import { MdOutlineSpaceDashboard, MdOutlinePriceChange, MdOutlinePassword } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { GrUserAdmin } from 'react-icons/gr';
import jwtDecode from 'jwt-decode';
import { CgProfile } from 'react-icons/cg';
import { useState } from 'react';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { HiOutlineLogout } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux';
import { TbHistory } from 'react-icons/tb';
function Navbar() {
const location = useLocation();
const navigate = useNavigate();
const isController = useSelector(state=> state.user.value.isController) || false;
const isAdmin = useSelector((state) => state.user.value.isAdmin) || false;
const dispatch = useDispatch();
const [toggled, setToggled] = useState(false);
let type;
if(!localStorage.getItem('token')) return (<></>);
try{
const token = jwtDecode(localStorage.getItem('token'));
if('isUser' in token){
  type = 'user';
}
else if(
  'aid' in token
) type= 'admin';
}
catch(err){
  console.log(err);
}

function handleLogout(){
  
    
    localStorage.removeItem('token') 
    window.location.href = `${window.location.origin}/`;
    
}
  return (
    <div className={`navbar${toggled ? ' toggled' : ''}`} onClick={()=> toggled && setToggled(false)} >
      <div className="nav">
      <svg version="1.2" className='logo' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 513 501" width="513" height="501">
	<g id="Layer 1">
		<path id="&lt;Path&gt;" d="m303.2 437.9l-40.9 43.3-169.5-160.2 40.9-43.3 126.2 119.3 121.8-128.9 43.3 40.9z"
    strokeLinejoin="round"/>
		<path id="&lt;Path&gt;" d="m264.7 349.6l-178.7-168.8 40.9-43.3 112-118.5 43.3 40.9-112 118.5 92.1 87 71.1-75.2-24.7-23.4-51.2 54.3-43.4-41 92.2-97.5 43.3 40.9 24.7 23.4 43.3 40.9z"
    strokeLinejoin="round"/>
	</g>
</svg>
      <div className="nav-links">
        <Link title='Dashboard' onClick={()=>setToggled(false)} to={`/${type}/dashboard`} className={`nav-link ${location.pathname === `/${type}/dashboard` ? 'active' : ''}`}><MdOutlineSpaceDashboard /></Link>
        {type === 'admin' ? <Link to='/admin/users' title='Users' className={`nav-link ${location.pathname === '/admin/users' ? 'active' : ''}`}><FiUsers /></Link> : null}
        {type === 'admin' && isController ? <Link to='/super-admin/admins' title='Admins' className={`nav-link ${location.pathname === '/super-admin/admins' ? 'active' : ''}`}><GrUserAdmin className='svg-stroke' /></Link> : null}
        {type === 'user' ? <Link title='My Profile' to='/user/profile' className={`nav-link ${location.pathname === '/user/profile' ? 'active' : ''}`}><CgProfile /></Link> : null}
        {/* {type === 'admin' ? <Link title='My Profile' to='/admin/profile' className={`nav-link ${location.pathname === '/admin/profile' ? 'active' : ''}`}><CgProfile /></Link> : null} */}
        {type === 'admin' ? <Link title='Users History' to='/admin/users/history' className={`nav-link ${location.pathname === '/admin/users/history' ? 'active' : ''}`}><TbHistory /></Link> : null}
        {type === 'admin' && isController ? <Link title='Pricing and Bundles' to='/admin/pricing'
        className={`nav-link ${location.pathname === '/admin/pricing' ? 'active' : ''}`}><MdOutlinePriceChange /></Link> : null}
        {type === 'admin' && isController ? <Link title='Admin History' to='/super-admin/admins/history' className={`nav-link ${location.pathname === '/super-admin/admins/history' ? 'active' : ''}`}><TbHistory /></Link> : null}
        {type === 'admin' && isController ? <Link title='Change Queuelist Password' to='/super-admin/queuelist/pass' className={`nav-link ${location.pathname === '/super-admin/queuelist/pass' ? 'active' : ''}`}><MdOutlinePassword /></Link> : null}
    </div>
    <button className='logout-btn' onClick={handleLogout} title='Sign out'><HiOutlineLogout /></button>
    </div>
    <button type='button' onClick={()=> setToggled(!toggled)} className={`toggle-bar${toggled ? ' flipped' : ''}`}><HiOutlineChevronDoubleRight /></button>
    </div>
  )
}

export default Navbar