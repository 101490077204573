import { configureStore } from '@reduxjs/toolkit'
import sectionsSlice from './sectionsReducer'
import userSlice from './userReducer';
import queuesSlice from './queuesReducer';
import tokenSlice from './tokenReducer';
import redirectSlice from './redirectReducer';
export const store = configureStore({
  reducer: {
    sections: sectionsSlice.reducer,
    user: userSlice.reducer,
    queues: queuesSlice.reducer,
    token: tokenSlice.reducer,
    redirect: redirectSlice.reducer
  }
});