import { lazy, Suspense, useState } from "react";
import "./App.css";
import NotFound from "./pages/notFound/NotFound";
import Home from "./pages/home/Home";
import { Route, Routes, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "./store/tokenReducer";
import { setRedirect } from "./store/redirectReducer";
import { setLoggedIn, setUser } from "./store/userReducer";
import { useLocation } from "react-router-dom";
import ExpiredPopup from "./components/expiredPopup/ExpiredPopup";
import Navbar from "./components/navbar/Navbar";
import Loading from "./components/loading/Loading";
import { SERVER_URL } from "./variables";
import AdminProfile from "./pages/adminProfile/AdminProfile";
import ChangeQueueListPass from "./pages/changeQueueListPass/ChangeQueueListPass";
import Guest from "./pages/guest/Guest";
import GuestVerify from "./pages/guestVerify/GuestVerify";
const History = lazy(() => import("./components/history/History"));
const ScreenQueueList = lazy(() =>
  import("./pages/screenQueueList/ScreenQueueList")
);
const SuperLogin = lazy(() => import("./pages/superLogin/SuperLogin"));
const Admins = lazy(() => import("./pages/admins/Admins"));
const CreateAdminProfile = lazy(() =>
  import("./pages/createAdminProfile/CreateAdminProfile")
);
const PublicAdminProfile = lazy(() =>
  import("./pages/publicAdminProfile/PublicAdminProfile")
);
const EditPublicAdminProfile = lazy(() =>
  import("./pages/editPublicAdminProfile/EditPublicAdminProfile")
);
const CreateUserProfile = lazy(() =>
  import("./pages/createUserProfile/CreateUserProfile")
);
const PublicUserProfile = lazy(() =>
  import("./pages/publicUserProfile/PublicUserProfile")
);
const EditPublicProfile = lazy(() =>
  import("./pages/editPublicProfile/EditPublicProfile")
);
const AdminLogin = lazy(() => import("./pages/adminLogin/AdminLogin"));
const AdminDashboard = lazy(() =>
  import("./pages/adminDashboard/AdminDashboard")
);
const UserLogin = lazy(() => import("./pages/userLogin/UserLogin"));
const UserDashboard = lazy(() => import("./pages/userDashboard/UserDashboard"));
const Users = lazy(() => import("./pages/users/Users"));
const NewUser = lazy(() => import("./pages/newUser/NewUser"));
const QueueList = lazy(() => import("./pages/queueList/QueueList"));
const EditProfile = lazy(() => import("./pages/editProfile/EditProfile"));
const UserProfile = lazy(() => import("./pages/userProfile/UserProfile"));
const Prices = lazy(() => import("./pages/prices/Prices"));
function App() {
  const redirect = useSelector((state) => state.redirect.value);
  const loggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.value.isAdmin) || false;
  const isController =
    useSelector((state) => state.user.value.isController) || false;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);
  const navClasses = loggedIn && !redirect ? "page with-nav" : "page";
  useEffect(() => {
    if (
      location.pathname.includes("screen") || location.pathname.includes("guest")
    )
      return;
    if (
      !localStorage.getItem("token") &&
      location.pathname != "/" &&
      location.pathname != "/queuelist/screen"
    ) {
      if (location.pathname.includes("login")) dispatch(setRedirect(""));
      else navigate(`/${!isAdmin ? "user" : "admin"}/login`);
    } else if (
      location.pathname.includes("queue") &&
      !location.pathname.includes("screen")
    ) {
      if (!localStorage.getItem("queue-list-token")) {
        localStorage.removeItem("token");
        localStorage.removeItem("queue-list-token");
        return dispatch(
          setRedirect(
            `/${!isAdmin ? "user" : isController ? "super" : "admin"}/login`
          )
        );
      }
      let claims;
      try {
        claims = jwtDecode(localStorage.getItem("token"));
        if (claims.exp > Math.floor(Date.now() / 1000) || !claims) {
          dispatch(setLoggedIn(true));
          dispatch(setToken(claims));
          if (claims.uid) {
            dispatch(setUser(claims));
          } else {
            if (claims.isAdmin)
              dispatch(
                setUser({
                  isAdmin: true,
                  ...(claims.isController ? { isController: true } : {}),
                  allowChangeAvailable: claims.allowChangeAvailable,
                })
              );
              else if(claims.isGuest){
                dispatch(setUser({isGuest: true}));
              }
          }
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("queue-list-token");
          dispatch(
            setRedirect(
              `/${claims.uid ? "user" : isController ? "super" : "admin"}/login`
            )
          );
        }
      } catch (err) {
        localStorage.removeItem("token");
        localStorage.removeItem("queue-list-token");
        if (
          (location.pathname.includes("user") ||
            location.pathname.includes("admin") ||
            location.pathname.includes("guest")
            ||
            location.pathname.includes("queue")) &&
          location.pathname != "/"
        ) {
          dispatch(setRedirect("/"));
          dispatch(setLoggedIn(false));
          console.log(err);
        }
      }
      if (location.pathname.includes("login")) dispatch(setRedirect(""));
    } else {
      if (location.pathname.includes("login")) dispatch(setRedirect(""));
      let claims;
      try {
        claims = jwtDecode(localStorage.getItem("token"));
        if (claims.exp > Math.floor(Date.now() / 1000) || !claims) {
          dispatch(setLoggedIn(true));
          dispatch(setToken(claims));
          if (claims.uid) {
            dispatch(setUser(claims));
          } else {
            if (claims.isAdmin)
              dispatch(
                setUser({
                  isAdmin: true,
                  ...(claims.isController ? { isController: true } : {}),
                  allowChangeAvailable: claims.allowChangeAvailable,
                })
              );
          }
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("queue-list-token");
          dispatch(setRedirect(`/${claims.uid ? "user" : "admin"}/login`));
        }
      } catch (err) {
        localStorage.removeItem("token");
        localStorage.removeItem("queue-list-token");
        if (
          (location.pathname.includes("user") ||
            location.pathname.includes("admin") ||
            location.pathname.includes("queue")) &&
          location.pathname != "/"
        ) {
          dispatch(
            setRedirect(
              `/${
                location.pathname.includes("user") ||
                location.pathname.includes("queue") ||
                location.pathname == "/"
                  ? "user"
                  : "admin"
              }/login`
            )
          );
          dispatch(setLoggedIn(false));
          console.log(err);
        }
      }
      if (!loggedIn && token.exp > Math.floor(Date.now() / 1000)) {
        dispatch(setLoggedIn(true));
      }
    }
  }, [loggedIn, redirect]);
  return (
    <div className="App">
      {loggedIn && !redirect ? <Navbar /> : null}
      <div className={navClasses}>
        {redirect ? (
          <ExpiredPopup
            to={redirect}
            onClicked={() => dispatch(setRedirect(""))}
          />
        ) : null}
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/user/login" element={<UserLogin />} />
            <Route path="/queuelist/screen" element={<ScreenQueueList />} />
            <Route path="/super/login" element={<SuperLogin />} />
            <Route path="/admin/users" element={!redirect ? <Users /> : null} />
            <Route
              path="/super-admin/admins"
              element={!redirect && isController ? <Admins /> : null}
            />
            <Route
              path="/super-admin/admin/new"
              element={
                !redirect && isController ? <CreateAdminProfile /> : null
              }
            />
            <Route
              path="/admins/:aid"
              element={
                !redirect && isAdmin && isController ? (
                  <PublicAdminProfile />
                ) : null
              }
            />
            <Route
              path="/admins/:aid/profile/edit"
              element={
                !redirect && isAdmin && isController ? (
                  <EditPublicAdminProfile />
                ) : null
              }
            />
            <Route
              path="/queuelist"
              element={!redirect ? <QueueList /> : null}
            />
            <Route
              path="/admin/users/history"
              element={
                !redirect && isAdmin ? (
                  <History
                    url={`${SERVER_URL}/admin/users/history`}
                    title="Users History"
                    key={1}
                  />
                ) : null
              }
            />
            <Route
              path="/super-admin/admins/history"
              element={
                !redirect && isController ? (
                  <History
                    url={`${SERVER_URL}/super/admins/history`}
                    title="Admins History"
                    key={2}
                  />
                ) : null
              }
            />
            <Route
              path="/admin/dashboard"
              element={!redirect ? <AdminDashboard /> : null}
            />
            <Route
              path="/user/:userName"
              element={!redirect && isAdmin ? <PublicUserProfile /> : null}
            />
            <Route
              path="/guest/:tokenId"
              element={!redirect ? <Guest /> : null}
            />
            <Route
              path="/guest/verify/:tokenId"
              element={!redirect ? <GuestVerify /> : null}
            />
            <Route
              path="/user/:userName/profile/edit"
              element={!redirect && isAdmin ? <EditPublicProfile /> : null}
            />
            <Route
              path="/user/dashboard"
              element={!redirect ? <UserDashboard /> : null}
            />
            <Route
              path="/guest/booking"
              element={!redirect ? <UserDashboard /> : null}
            />
            <Route
              path="/user/credentials"
              element={!redirect ? <NewUser /> : null}
            />
            <Route
              path="/user/profile"
              element={!redirect ? <UserProfile /> : null}
            />
            <Route
              path="/user/profile/edit"
              element={!redirect ? <EditProfile /> : null}
            />
            <Route
              path="/admin/pricing"
              element={!redirect && isController ? <Prices /> : null}
            />
            <Route
              path="/admin/profile"
              element={!redirect && isAdmin ? <AdminProfile /> : null}
            />
            <Route
              path="/admin/user/new"
              element={!redirect && isAdmin ? <CreateUserProfile /> : null}
            />
            <Route
              path="/super-admin/queuelist/pass"
              element={
                !redirect && isController ? <ChangeQueueListPass /> : null
              }
            />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
